<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入终端编号" v-model="query.terminalNo"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="onAdd" v-if="permission.terminalSave">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="terminalNo" min-width="120" label="终端编号" align="center"></el-table-column>
                <el-table-column label="门店logo" min-width="100" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.storeLogo"
                            :preview-src-list="[scope.row.storeLogo]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="storeName" label="门店名称" min-width="160" align="center"></el-table-column>
                <el-table-column prop="storeAddress" label="门店地址" min-width="230" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110px" align="center">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.terminalUpdate">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.terminalRemove">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看设备详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加设备 -->
        <el-dialog :title="`${editForm.id ? '编辑' : '添加'}设备`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="终端编号" prop="terminalNo">
                    <el-input size="medium" v-model="editForm.terminalNo" placeholder="请输入终端编号"></el-input>
                </el-form-item>
                <el-form-item label="关联门店" prop="storeName" class="is-required">
                    <el-input size="medium" v-model="editForm.storeName" placeholder="请选择门店" disabled style="width: 400px">
                    </el-input>
                    <el-button style="margin-left: 20px" type="primary" plain @click="createIdChangeNew">选择</el-button>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 选择门店 -->
        <el-dialog title="选择门店" :visible.sync="dialogStoreFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <StoreList :key="'key' + currentKey" @changeSelectStore="handleChangeSelectStore"
                v-if="dialogStoreFormVisible">
            </StoreList>
        </el-dialog>
    </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import systemSetManager from '@/request/systemSetManager.js'
import { terminalModule } from "@/request/systemSetManager.js";
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import StoreList from "@/components/page/store/StoreList.vue";

export default {
    mixins: [getList],
    components: {
        ShowContent,
        StoreList,
    },
    data() {
        return {
            requst: {
                module: systemSetManager,
                url: 'terminalList',
                addUrl: '',
                updateUrl: '',
            },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                terminalNo: '', //搜索词
            },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                id: 0,
                terminalNo: '',
                storeId: '',
                storeName: '',
            },
            addRules: {
                terminalNo: [
                    { required: true, message: '请输入终端编号', trigger: 'blur' },
                ],
                storeName: [{ required: true, message: "请选择门店", trigger: "blur" }],
            },
            // 
            currentKey: 1,
            dialogStoreFormVisible: false,
        }
    },
    created() { },
    methods: {
        hoverContent(row) {
            this.rowInfo = row
            this.dialogSeeContent = true
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    this.addOrEditBtnLoading = true
                    let dataValue = JSON.parse(JSON.stringify(this.editForm))
                    if (this.editForm.id > 0) {
                        await terminalModule.terminalUpdate(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    } else {
                        await terminalModule.terminalSave(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    }
                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                id: 0,
                terminalNo: '',
                storeId: '',
                storeName: '',
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm = {
                id: row.id,
                terminalNo: row.terminalNo,
                storeId: row.storeId,
                storeName: row.storeName,
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.terminalRemove({
                    id: row.id,
                })
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success',
                    })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        createIdChangeNew() {
            this.dialogStoreFormVisible = true;
        },
        handleChangeSelectStore(store) {
            console.log(store);
            this.editForm.storeId = store.storeId;
            this.editForm.storeName = store.storeName;
            this.dialogStoreFormVisible = false;
        },




    },
}
</script>

<style scoped></style>