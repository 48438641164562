<template>
  <div class="container">
    <div class="page-container">
      <div class="regionContent">
        <el-tree :data="tableData" node-key="regionId" :props="defaultProps" @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse" :default-expanded-keys="defaultExpandIds" >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @mouseenter="mouseenter(data)"
            @mouseleave="mouseleave(data)"
            style="width: 100%"
          >
            <span v-show="node.label != ''"> {{ node.label }} </span>
            <span
              class="button"
              v-show="data.del && !showEdit[data.regionId] && data.layerId !== -1"
            >
              <!-- 添加同级 -->
              <el-button
                type="text"
                size="mini"
                @click.stop="() => addRegionClick(node, data, 1)"
                v-show="data.layerId !== 0 && permission.saveRegionInfo"
              >
                添加同级
              </el-button>
              <!-- 添加子级 -->
              <el-button
                type="text"
                size="mini"
                @click.stop="() => addRegionClick(node, data, 2)"
                v-show="data.layerId !== 3 && permission.saveRegionInfo"
              >
                添加子级
              </el-button>
              <!-- 编辑 -->
              <el-button
                type="text"
                size="mini"
                @click.stop="() => editbankclassgetype(node, data)"
                v-show="data.layerId !== 0 && permission.updateRegionInfo"
              >
                修改
              </el-button>
              <!-- 删除 -->
              <el-popconfirm
                title="您确定要删除么？"
                iconColor="red"
                @confirm="() => removeknowledgetype(node, data)"
                v-show="data.layerId !== 0 && permission.removeRegionInfo"
                :ref="`popover-${data.regionId}`"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="margin-left: 10px"
                  @click.stop="closePopover"
                  >删除</el-button
                >
              </el-popconfirm>
            </span>
          </span>
        </el-tree>
      </div>
    </div>

    <!-- 添加同级 -->
    <el-dialog
      :title="showTypeTitle"
      :visible.sync="addSameLevelRegion"
      :modal="false"
      :destroy-on-close="true"
      fullscreen
      @close="resetAddOrEditData"
    >
      <el-form
        :model="addRegion"
        :rules="addRules"
        ref="addRegion"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="地区名称" prop="regionName">
          <el-input
            size="medium"
            v-model="addRegion.regionName"
            placeholder="请填写关键字"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否为偏远地区">
          <el-checkbox
            v-model="addRegion.isRemote"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
      </el-form>

      <!-- 取消确定 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetAddOrEditData">取 消</el-button>
        <el-button
          size="medium"
          icon="el-icon-document-checked"
          type="primary"
          :loading="addSameRegionLoading"
          @click="onAddSameRegionSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import systemSetManager from "@/request/systemSetManager.js";
export default {
  mixins: [getList],
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "regionName",
      },
      currendNode: {}, //记录的node
      currendData: {}, //用于data
      showEdit: [],

      //1,添加同级 2,添加子级 3修改
      showType: 0,
      //弹出的标题
      showTypeTitle: "",

      addSameLevelRegion: false,
      addSameRegionLoading: false,
      //添加地址的model
      addRegion: {
        layerId: 0,
        parentId: 0,
        regionName: "",
        isRemote: 0,
        regionId: 0,
        initialLetter: "",
        children: [],
      },
      addRules: {
        regionName: [{ required: true, message: "请输入地区名称", trigger: "blur" }],
      },
      defaultExpandIds: [],
    };
  },
  created() {
    this.handlePermission();
    this.getRegionList();
  },
  methods: {
    //获取城市列表
    async getRegionList() {
      this.showLoading = true;
      // console.log(this.);
      try {
        let data = await systemSetManager.regionList();
        // this.tableData = [];
        if (data.code == 200) {
          this.tableData = this.toTree(data.data);
        }
        this.showLoading = false;
      } catch (error) {
        this.showLoading = false;
      }
    },
    //处理数据
    toTree(data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach((item) => {
        item.children = [];
      });
      let map = {};
      data.forEach((item) => {
        map[item.regionId] = item;
      });
      data.forEach((item) => {
        let parent = map[item.parentId];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      console.log("totree");
      return result;
    },

    //进入
    mouseenter(data) {
      this.$set(data, "del", true);
    },
    //离开
    mouseleave(data) {
      this.$set(data, "del", false);
    },
    resetAddOrEditData() {
      this.addRegion.children = [];
      this.addRegion.isRemote = 0;
      this.addRegion.parentId = 0;
      this.addRegion.regionId = 0;
      this.addRegion.regionName = "";
      this.addRegion.initialLetter = "";
      this.currendNode = {};
      this.currendData = {};
      this.addSameLevelRegion = false;
    },

    // 添加同级/子级 type 1,同级 2.子级
    addRegionClick(node, data, type) {
      this.showType = type;
      if (type == 1) {
        this.showTypeTitle = "添加同级";
      } else {
        this.showTypeTitle = "添加子级";
      }
      //记录要修改的对象
      this.currendNode = node;
      this.currendData = data;
      //把操作元素初始值
      this.addSameLevelRegion = true;
    },

    //修改
    editbankclassgetype(node, data) {
      this.showType = 3;
      this.showTypeTitle = "修改";
      this.currendData = data;
      this.addRegion.children = data.children;
      this.addRegion.isRemote = data.isRemote;
      this.addRegion.parentId = data.parentId;
      this.addRegion.regionId = data.regionId;
      this.addRegion.regionName = data.regionName;
      this.addRegion.initialLetter = data.initialLetter;
      this.addSameLevelRegion = true;
    },
    closePopover() {
      //每次只显示一个Popover弹窗 使用cancel()
      // 遍历所有的refs，判断索引名中是否有 'popover-' ;如果有，都使用cancel关掉
      for (const key in this.$refs) {
        if (key.indexOf("popover-") !== -1) {
          if (typeof this.$refs[key] !== "undefined") {
            this.$refs[key].cancel();
          }
        }
      }
    },
    //删除
    async removeknowledgetype(node, dataNode) {
      try {
        let data = await systemSetManager.regionRemove({ regionId: dataNode.regionId });
        if (data.code == 200) {
          if (data.code == 200) {
            this.$message.success(data.message);
            this.getRegionList();
            this.resetAddOrEditData();
          }
        }
      } catch (error) {
        //
      }
    },

    //添加地区
    onAddSameRegionSubmit() {
      this.$refs.addRegion.validate(async (valid) => {
        if (valid) {
          // 添加同级地区
          if (this.showType == 1) {
            this.addRegionInfo(
              this.addRegion.regionName,
              this.currendData.parentId,
              this.currendData.layerId,
              this.addRegion.isRemote,
              this.addRegion.initialLetter
            );
          } else if (this.showType == 2) {
            // 添加子级地区
            this.addRegionInfo(
              this.addRegion.regionName,
              this.currendData.regionId,
              this.currendData.layerId + 1,
              this.addRegion.isRemote,
              this.addRegion.initialLetter
            );
          } else {
            this.editRegionInfo(
              this.addRegion.regionId,
              this.addRegion.regionName,
              this.addRegion.isRemote
            );
          }
        } else {
          return false;
        }
      });
    },

    //添加地区
    async addRegionInfo(regionName, parentId, layerId, isRemote, initialLetter) {
      this.addSameRegionLoading = true;
      try {
        let data = await systemSetManager.regionSave({
          layerId: layerId,
          regionName: regionName,
          parentId: parentId,
          isRemote: isRemote,
          initialLetter: initialLetter,
        });
        if (data.code == 200) {
          this.$message.success(data.message);
          this.getRegionList();
          this.resetAddOrEditData();
        }
        this.addSameRegionLoading = false;
      } catch (error) {
        this.addSameRegionLoading = false;
      }
    },
    //MARK:修改地区信息接口
    async editRegionInfo(regionId, regionName, isRemote) {
      this.addSameRegionLoading = true;
      try {
        let data = await systemSetManager.regionUpdate({
          regionId: regionId,
          regionName: regionName,
          isRemote: isRemote,
        });
        if (data.code == 200) {
          this.$message.success(data.message);
          this.getRegionList();
          this.resetAddOrEditData();
        }
        this.addSameRegionLoading = false;
      } catch (error) {
        this.addSameRegionLoading = false;
      }
    },
    // 树节点展开
    handleNodeExpand(data) {
      // 保存当前展开的节点
      let flag = false;
      this.defaultExpandIds.some((item) => {
        if (item === data.regionId) {
          // 判断当前节点是否存在， 存在不做处理
          flag = true;
          return;
        }
      });
      if (!flag) {
        // 不存在则存到数组里
        this.defaultExpandIds.push(data.regionId);
      }
    },
    // 树节点关闭
    handleNodeCollapse(data) {
      // 删除当前关闭的节点
      this.defaultExpandIds.some((item, i) => {
        if (item === data.regionId) {
          this.defaultExpandIds.splice(i, 1);
        }
      });
      // 这里主要针对多级树状结构，当关闭父节点时，递归删除父节点下的所有子节点
      this.removeChildrenIds(data);
    },
    // 删除树子节点
    removeChildrenIds(data) {
      const ts = this;
      //懒加载的时候想要删除子节点  得在加载下级数据时添加上data.children
      if (data.children) {
        data.children.forEach(function (item) {
          const index = ts.defaultExpandIds.indexOf(item.regionId);
          if (index != -1) {
            ts.defaultExpandIds.splice(index, 1);
          }
          ts.removeChildrenIds(item);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-left: 100px;
}
.custom-tree-node {
  line-height: 40px;
}
</style>
