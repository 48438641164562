<template>
  <div class="warp fr-s-c">
    <div class="fr-s-c relative m-r-10" v-for="(i, v) in fileList" :key="v">
      <el-image class="img-view" :src="i" :preview-src-list="fileList">
        <div class="w-1 h-1 fr-c" slot="error" style="background: #f9f9fa">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <div class="fr-c del-content" @click.stop="hoverDel(v)">
        <img src="../../../assets/img/del_icon.png" alt="" />
      </div>
    </div>
    <div class="choose-img fr-c img-view relative" v-loading="fileLoading" v-if="fileList.length < limit">
      <img src="../../../assets/img/uploadImg.png" alt="" />
      <input id="choose" ref="choose" accept="image/*" :multiple="limit > 1" @change="changeFile" type="file" class="img-view" style="position: absolute; opacity: 0" />
    </div>
  </div>
</template> 

<script>
import systemManager from '@/request/systemManager.js'
export default {
  props: {
    imgList: {
      type: [String, Array],
      default: '',
    },
    limit: {
      type: Number,
      default: 9,
    },
  },
  watch: {
    imgList: function (newval) {
      if (Array.isArray(newval)) {
        this.fileList = newval
      } else {
        if (newval) {
          this.fileList = [newval]
        } else {
          this.fileList = []
        }
      }
    },
  },
  data() {
    return {
      fileLoading: false,
      fileList: [],
    }
  },
  /**2022-07-19⬇️ */
  created() {
    if (Array.isArray(this.imgList)) {
      this.fileList = this.imgList
    } else {
      if (this.imgList) {
        this.fileList = [this.imgList]
      } else {
        this.fileList = []
      }
    }
  },
  /**2022-07-19⬆️ */
  mounted() {},
  methods: {
    async changeFile() {
      try {
        if (!this.$refs.choose.files.length) return
        this.fileLoading = true
        let { data, code, message } = await systemManager.uploadfile(
          Array.from(this.$refs.choose.files).slice(
            0,
            this.limit - this.imgList.length
          )
        )
        if (code == 200) {
          if (data[0].url) {
            this.$emit(
              'update:imgList',
              Array.isArray(this.imgList)
                ? [...this.imgList, ...data.map((i) => i.url)]
                : data[0].url
            )
          } else {
            this.$emit(
              'update:imgList',
              Array.isArray(this.imgList)
                ? [...this.imgList, ...data.map((i) => i.sourceImg)]
                : data[0].sourceImg
            )
          }
        } else {
          this.$message.error(message || '图片上传失败')
        }
      } catch (error) {
        this.$message.error('图片上传失败')
      } finally {
        this.fileLoading = false
        document.getElementById('choose').value = ''
      }
    },
    hoverDel(id) {
      this.$emit(
        'update:imgList',
        Array.isArray(this.imgList)
          ? this.fileList.filter((i, v) => v != id)
          : ''
      )
    },
  },
}
</script>

<style scoped="scoped">
.img-view {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}
.choose-img {
  background-color: #f5f5f5;
}
.choose-img img {
  width: 20px;
  height: 20px;
}
.del-content {
  position: absolute;
  right: -10px;
  top: -9px;
  padding: 4px;
  cursor: pointer;
}
.del-content img {
  width: 18px;
  height: 18px;
}
</style>
