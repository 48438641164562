<template>
  <div class="container">
    <div class="page-container">
      <el-table ref="table" :data="tableData" border stripe :row-key="'rateId'" v-loading="showLoading"
        :height="tableHeight">
        <el-table-column prop="rateId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="rateTitle" label="标题" min-width="120"></el-table-column>
        <el-table-column prop="rateContent" label="内容" align="center" width="140">
          <!-- <template slot-scope="scope">
            {{ scope.row.rateContent }}
          </template> -->
          <template slot-scope="scope">
            <!-- is_editor【0：输入框，1：编辑器，2：图片，3：多行文本（texare）4：是否（checkbox）5：数值型 6：时间类型 7:客服电话 8：微信号】-->
            <span v-if="scope.row.isEditor == 4">
              <i :class="[scope.row.rateContent == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
            </span>
            <span v-else>
              {{ scope.row.rateContent }}
            </span>
          </template>
        </el-table-column>           
        <el-table-column label="操作" width="130px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button class="success" icon="el-icon-edit-outline" type="text" v-if="permission.updateRateSetting"
                @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>

    <el-dialog title="编辑设置" :visible.sync="dialogEditFormVisible" :modal="false" :destroy-on-close="true" fullscreen
      @close="resetAddOrEditData">
      <el-form ref="editForm" label-width="120px" :model="editForm" :rules="editRules">
        <el-form-item label="标题:" :label-width="formLabelWidth">
          <el-input v-model.trim="editForm.rateTitle" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="内容:" :label-width="formLabelWidth" prop="rateContent">
          <span v-if="editForm.isEditor == 4">
            <el-checkbox v-model="editForm.rateContent" :true-label="1" :false-label="0"></el-checkbox>
          </span>
          <span v-else>
            <el-input type="number" v-model="editForm.rateContent"></el-input>
          </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" icon="el-icon-switch-button" @click="dialogEditFormVisible = false">取 消</el-button>
        <el-button size="medium" icon="el-icon-document-checked" type="primary" :loading="editbuttonloading"
          @click="onEditSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import systemSetManager from "@/request/systemSetManager.js";
export default {
  mixins: [getList],
  data() {
    return {
      requst: {
        module: systemSetManager,
        url: "rateSettingList",
        addUrl: "",
        updateUrl: "",
      },
      query: {},
      dialogEditFormVisible: false,
      editbuttonloading: false,
      editRules: {
        rateContent: [
          { required: true, message: "请输入内容", trigger: "blur" },
          //   {
          //     pattern: /^-?\d+\.?\d*$/,
          //     message: "内容必须为数字",
          //     trigger: "change",
          //   },
        ],
      },
      formLabelWidth: "120px",
      editForm: {
        rateId: 0,
        rateTitle: "",
        rateContent: "",
        isEditor: "",
      },
    };
  },
  methods: {
    clickEditInfo(row) {
      this.editForm.rateId = row.rateId;
      this.editForm.rateTitle = row.rateTitle;
      this.editForm.rateContent = row.rateContent*1;
      this.editForm.isEditor = row.isEditor;
      this.dialogEditFormVisible = true;
    },
    resetAddOrEditData() {
      this.editForm.rateId = 0;
      this.editForm.rateTitle = "";
      this.editForm.rateContent = "";
      this.editForm.isEditor = "";
      this.dialogEditFormVisible = false;
    },
    onEditSubmit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = await systemSetManager.rateSettingUpdate(this.editForm);
            if (data.code == 200) {
              this.$message.success(data.message);
              this.refresCurrentPagehData();
              this.resetAddOrEditData();
            }
          } catch (error) {
            // console.log(error);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
