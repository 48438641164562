var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "regionContent" },
          [
            _c("el-tree", {
              attrs: {
                data: _vm.tableData,
                "node-key": "regionId",
                props: _vm.defaultProps,
                "default-expanded-keys": _vm.defaultExpandIds,
              },
              on: {
                "node-expand": _vm.handleNodeExpand,
                "node-collapse": _vm.handleNodeCollapse,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ node, data }) {
                    return _c(
                      "span",
                      {
                        staticClass: "custom-tree-node",
                        staticStyle: { width: "100%" },
                        on: {
                          mouseenter: function ($event) {
                            return _vm.mouseenter(data)
                          },
                          mouseleave: function ($event) {
                            return _vm.mouseleave(data)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: node.label != "",
                                expression: "node.label != ''",
                              },
                            ],
                          },
                          [_vm._v(" " + _vm._s(node.label) + " ")]
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  data.del &&
                                  !_vm.showEdit[data.regionId] &&
                                  data.layerId !== -1,
                                expression:
                                  "data.del && !showEdit[data.regionId] && data.layerId !== -1",
                              },
                            ],
                            staticClass: "button",
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.layerId !== 0 &&
                                      _vm.permission.saveRegionInfo,
                                    expression:
                                      "data.layerId !== 0 && permission.saveRegionInfo",
                                  },
                                ],
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.addRegionClick(node, data, 1)).apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 添加同级 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.layerId !== 3 &&
                                      _vm.permission.saveRegionInfo,
                                    expression:
                                      "data.layerId !== 3 && permission.saveRegionInfo",
                                  },
                                ],
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.addRegionClick(node, data, 2)).apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 添加子级 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.layerId !== 0 &&
                                      _vm.permission.updateRegionInfo,
                                    expression:
                                      "data.layerId !== 0 && permission.updateRegionInfo",
                                  },
                                ],
                                attrs: { type: "text", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (() =>
                                      _vm.editbankclassgetype(
                                        node,
                                        data
                                      )).apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" 修改 ")]
                            ),
                            _c(
                              "el-popconfirm",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.layerId !== 0 &&
                                      _vm.permission.removeRegionInfo,
                                    expression:
                                      "data.layerId !== 0 && permission.removeRegionInfo",
                                  },
                                ],
                                ref: `popover-${data.regionId}`,
                                attrs: {
                                  title: "您确定要删除么？",
                                  iconColor: "red",
                                },
                                on: {
                                  confirm: () =>
                                    _vm.removeknowledgetype(node, data),
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      slot: "reference",
                                      type: "text",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.closePopover.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.showTypeTitle,
            visible: _vm.addSameLevelRegion,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addSameLevelRegion = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addRegion",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.addRegion,
                rules: _vm.addRules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "地区名称", prop: "regionName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请填写关键字" },
                    model: {
                      value: _vm.addRegion.regionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRegion, "regionName", $$v)
                      },
                      expression: "addRegion.regionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否为偏远地区" } },
                [
                  _c("el-checkbox", {
                    attrs: { "true-label": 1, "false-label": 0 },
                    model: {
                      value: _vm.addRegion.isRemote,
                      callback: function ($$v) {
                        _vm.$set(_vm.addRegion, "isRemote", $$v)
                      },
                      expression: "addRegion.isRemote",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetAddOrEditData } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    icon: "el-icon-document-checked",
                    type: "primary",
                    loading: _vm.addSameRegionLoading,
                  },
                  on: { click: _vm.onAddSameRegionSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }