var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择类型", clearable: "" },
                      model: {
                        value: _vm.query.advertType,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "advertType", $$v)
                        },
                        expression: "query.advertType",
                      },
                    },
                    _vm._l(_vm.searchForm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择展示模块", clearable: "" },
                      model: {
                        value: _vm.query.moduleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "moduleId", $$v)
                        },
                        expression: "query.moduleId",
                      },
                    },
                    _vm._l(_vm.searchForm.moduleList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveAdvertInfo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "advertId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "advertId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图片", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            staticStyle: {
                              "max-width": "40px",
                              "max-height": "40px",
                            },
                            attrs: {
                              src: scope.row.imgUrl,
                              "preview-src-list": [scope.row.imgUrl],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "advertTitle",
                  label: "广告标题",
                  align: "center",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "广告类型",
                  width: "100px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.advertType,
                                _vm.searchForm.typeList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "展示模块",
                  width: "120px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("getStateName")(
                                scope.row.moduleId,
                                _vm.searchForm.moduleList
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isShow", label: "是否显示", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          class: [
                            scope.row.isShow == 1
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "详情",
                  width: "120px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.advertType == 1 || scope.row.advertType == 2
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.linUrlDetail(scope.row)
                                  },
                                },
                              },
                              [
                                _c("el-link", { attrs: { type: "primary" } }, [
                                  _vm._v("查看详情"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "orderWeight",
                  label: "权重",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _vm.permission.updateAdvertInfo
                              ? _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "top",
                            },
                          },
                          [
                            _vm.permission.removeAdvertInfo
                              ? _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.advertId ? "编辑" : "添加"}广告`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "广告标题", prop: "advertTitle" },
                },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请填写广告标题" },
                    model: {
                      value: _vm.editForm.advertTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "advertTitle", $$v)
                      },
                      expression: "editForm.advertTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片", prop: "imgUrl" } },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.editForm.imgUrl,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(_vm.editForm, "imgUrl", $event)
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(_vm.editForm, "imgUrl", $event)
                      },
                    },
                  }),
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v("最优比例117:40"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "广告类型", prop: "advertType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择类型" },
                      on: { change: _vm.changeAdverType },
                      model: {
                        value: _vm.editForm.advertType,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "advertType", $$v)
                        },
                        expression: "editForm.advertType",
                      },
                    },
                    _vm._l(_vm.searchForm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "展示模块", prop: "moduleId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择展示模块" },
                      model: {
                        value: _vm.editForm.moduleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "moduleId", $$v)
                        },
                        expression: "editForm.moduleId",
                      },
                    },
                    _vm._l(_vm.searchForm.moduleList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.editForm.advertType == 2
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "is-required",
                      attrs: { label: "外部链接", prop: "linkUrl" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请填写外部链接",
                        },
                        model: {
                          value: _vm.editForm.linkUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "linkUrl", $$v)
                          },
                          expression: "editForm.linkUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.advertType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "内容", prop: "advertContent" } },
                    [
                      _vm.addOrEditDialog
                        ? _c("Editor", {
                            model: {
                              value: _vm.editForm.advertContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "advertContent", $$v)
                              },
                              expression: "editForm.advertContent",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.advertType == 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "圈子信息", prop: "keyId" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          on: { click: _vm.selectCircleHandle },
                        },
                        [_vm._v("选择圈子")]
                      ),
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "圈子编号",
                          disabled: "",
                        },
                        model: {
                          value: _vm.editForm.keyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "keyId", $$v)
                          },
                          expression: "editForm.keyId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示", prop: "isShow" } },
                [
                  _c("el-checkbox", {
                    attrs: { "false-label": 0, "true-label": 1 },
                    model: {
                      value: _vm.editForm.isShow,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isShow", $$v)
                      },
                      expression: "editForm.isShow",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "权重", prop: "orderWeight" } },
                [
                  _c("el-input", {
                    attrs: { type: "number", placeholder: "请填写权重" },
                    model: {
                      value: _vm.editForm.orderWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "orderWeight", $$v)
                      },
                      expression: "editForm.orderWeight",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetAddOrEditData } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "圈子列表",
            visible: _vm.dialogSelectCircleVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectCircleVisible = $event
            },
          },
        },
        [
          _vm.dialogSelectCircleVisible
            ? _c("CircleList", { on: { selectCircle: _vm.changeCircleHandle } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }