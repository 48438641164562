var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请填写任务描述" },
                    model: {
                      value: _vm.query.taskDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "taskDesc", $$v)
                      },
                      expression: "query.taskDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请填写商品名" },
                    model: {
                      value: _vm.query.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "goodsName", $$v)
                      },
                      expression: "query.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.taskSave
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "taskId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "taskId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "任务logo", width: "90", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.taskLogo,
                              "preview-src-list": [scope.row.taskLogo],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taskTitle",
                  label: "任务名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "taskDesc", label: "任务描述", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showContentHandle(scope.row, 1)
                              },
                            },
                          },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v("查看"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "completedNumber",
                  label: "完成任务所需数量",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsName", label: "奖品", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "110px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.taskUpdate
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.taskRemove
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.taskId ? "编辑" : "添加"}任务`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "140px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "任务logo", prop: "taskLogo" },
                },
                [
                  _c("UploadImageNewOne", {
                    attrs: {
                      limit: 1,
                      imgList: _vm.editForm.taskLogo,
                      accept: "image/*",
                    },
                    on: {
                      "update:imgList": function ($event) {
                        return _vm.$set(_vm.editForm, "taskLogo", $event)
                      },
                      "update:img-list": function ($event) {
                        return _vm.$set(_vm.editForm, "taskLogo", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务名称", prop: "taskTitle" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请填写任务名称" },
                    model: {
                      value: _vm.editForm.taskTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "taskTitle", $$v)
                      },
                      expression: "editForm.taskTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "任务描述", prop: "taskDesc" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "textarea",
                      maxlength: "255",
                      "prefix-icon": "el-icon-user",
                      placeholder: "请填写任务描述",
                    },
                    model: {
                      value: _vm.editForm.taskDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "taskDesc", $$v)
                      },
                      expression: "editForm.taskDesc",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "完成任务所需数量", prop: "completedNumber" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      type: "number",
                      placeholder: "请填写完成任务所需数量",
                    },
                    model: {
                      value: _vm.editForm.completedNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "completedNumber", $$v)
                      },
                      expression: "editForm.completedNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "奖品", prop: "goodsName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请选择奖品", disabled: "" },
                    model: {
                      value: _vm.editForm.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "goodsName", $$v)
                      },
                      expression: "editForm.goodsName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.createIdChangeNew },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择奖品",
            visible: _vm.dialogGoodsFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGoodsFormVisible = $event
            },
          },
        },
        [
          _vm.dialogGoodsFormVisible
            ? _c("GoodsList", {
                key: "key" + _vm.currentKey,
                attrs: { goodsType: 2 },
                on: { changeSelectGoods: _vm.handleChangeSelectGoods },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }