<template>
  <div class="container">
    <div class="page-container">
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        :row-key="'explainId'"
        v-loading="showLoading"
        :height="tableHeight"
      >
        <el-table-column prop="explainId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          prop="explainTitle"
          label="标题"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="explainContent" label="内容" min-width="120">
          <template slot-scope="scope">
            <!-- is_editor【0：输入框，1：编辑器，2：图片，3：多行文本（texare）4：是否（checkbox）5：数值型 6：时间类型 7:客服电话 8：微信号】-->
            <span v-if="scope.row.isEditor == 2">
              <el-image
                class="table-td-thumb"
                style="margin: 0px"
                :src="scope.row.explainContent"
                :preview-src-list="[scope.row.explainContent]"
                fit="cover"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </span>
            <span v-else-if="scope.row.isEditor == 4">
              <el-button
                v-if="scope.row.explainContent == 1"
                size="mini"
                type="success"
                icon="el-icon-check"
                circle
              ></el-button>
              <el-button
                v-else
                type="danger"
                size="mini"
                icon="el-icon-close"
                circle
              ></el-button>
            </span>
            <span v-else-if="scope.row.isEditor == 1">
              <span @click="getDetail(scope.row)"
                ><el-link type="primary">详情</el-link></span
              >
            </span>
            <span v-else>
              {{ scope.row.explainContent }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="130px" align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button
                class="success"
                icon="el-icon-edit-outline"
                type="text"
                v-if="permission.updateExplainSetting"
                @click="clickEditInfo(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        ref="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="query.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
      >
      </el-pagination>
    </div>

    <!--修改说明设置  -->
    <el-dialog
      :title="`${editForm.helperId ? '编辑' : '添加'}说明设置`"
      :visible.sync="addOrEditDialog"
      :modal="false"
      :destroy-on-close="true"
      fullscreen
      @close="resetAddOrEditData"
    >
      <el-form ref="editForm" label-width="120px" :rules="addRules" :model="editForm">
        <el-form-item label="标题" prop="explainTitle">
          <el-input
            size="medium"
            style="width: 400px"
            v-model="editForm.explainTitle"
            placeholder="请输入标题"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="详情" prop="explainContent" class="is-required">
          <!-- 【0：输入框，1：编辑器，2：图片，3：多行文本（texare）4：是否（checkbox）5：数值型 6：时间类型 7:客服电话 8：微信号】 -->
          <!-- 【0：输入框，1：编辑器，2：图片，3：多行文本（texare）4：是否（checkbox）5：数值型 6：时间类型】 -->
          <span v-if="editForm.isEditor == 2">
            <div class="uploadImg">
              <UploadImage
                :limit="1"
                :imgList.sync="editForm.explainContent"
                accept="image/*"
              ></UploadImage>
            </div>
          </span>
          <span v-else-if="editForm.isEditor == 1">
            <Editor v-if="addOrEditDialog" v-model="editForm.explainContent"></Editor>
          </span>
          <span v-else-if="editForm.isEditor == 4">
            <el-button
              v-if="editForm.explainContent == 1"
              size="mini"
              type="success"
              icon="el-icon-check"
              circle
            ></el-button>
            <el-button
              v-else
              type="danger"
              size="mini"
              icon="el-icon-close"
              circle
            ></el-button>
          </span>
          <span v-else-if="editForm.isEditor == 3">
            <el-input
              type="textarea"
              style="width: 600px"
              :rows="8"
              placeholder="请输入内容"
              v-model="editForm.explainContent"
            ></el-input>
          </span>
          <!-- 时间类型 -->
          <span v-else-if="editForm.isEditor == 6"> </span>
          <span v-else>
            <el-input
              style="width: 1000px"
              v-model="editForm.explainContent"
              placeholder="请输入内容"
            ></el-input>
          </span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="resetAddOrEditData">取 消</el-button>
        <el-button
          size="medium"
          type="primary"
          :loading="addOrEditBtnLoading"
          @click="onEditSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <show-content
      v-model="dialogSeeContent"
      :title="rowInfo.title"
      :content="rowInfo.content"
    ></show-content>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import systemSetManager from "@/request/systemSetManager.js";
import UploadImage from "@/components/common/subgroup/UploadImage.vue"; //上传图片
import Editor from "@/components/common/subgroup/Editor.vue"; //编辑器
import ShowContent from "@/components/common/subgroup/ShowContent.vue";
export default {
  mixins: [getList],
  components: {
    ShowContent,
    UploadImage,
    Editor,
  },
  data() {
    return {
      requst: {
        module: systemSetManager,
        url: "explainSettingList",
        addUrl: "",
        updateUrl: "explainSettingUpdate",
      },
      query: {},
      //使用帮助
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        explainId: 0,
        explainTitle: "",
        explainContent: "",
        isEditor: "",
      },

      addRules: {
        helperTitle: [{ required: true, message: "请输入标题", trigger: "blur" }],
        helperContent: [{ required: true, message: "请输入内容", trigger: "blur" }],
        orderWeight: [{ required: true, message: "请输入权重", trigger: "change" }],
      },

      dialogSeeContent: false,
      rowInfo: {},
    };
  },
  methods: {
    resetAddOrEditData() {
      this.addOrEditDialog = false;
      this.editForm = {
        explainId: 0,
        explainTitle: "",
        explainContent: "",
        isEditor: "",
      };
    },
    getDetail(row) {
      this.rowInfo.title = row.explainTitle;
      this.rowInfo.content = row.explainContent;
      this.dialogSeeContent = true;
    },
    clickEditInfo(row) {
      this.editForm.explainId = row.explainId;
      this.editForm.explainTitle = row.explainTitle;
      this.editForm.isEditor = row.isEditor;
      if (row.isEditor == 1) {
        if (row.explainContent.indexOf("<p>") == -1) {
          this.editForm.explainContent = "<p>" + row.explainContent + "</p>";
        } else {
          this.editForm.explainContent = row.explainContent;
        }
      }else{
        this.editForm.explainContent = row.explainContent;
      }
      this.addOrEditDialog = true;
    },
    onEditSubmit(row) {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addbuttonloading = true;
            let data = await systemSetManager.explainSettingUpdate(this.editForm);
            if (data.code == 200) {
              this.$message.success(data.message);
              this.refreshData();
              this.resetAddOrEditData();
            }
            this.addbuttonloading = false;
          } catch (error) {
            this.addOrEditBtnLoading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
