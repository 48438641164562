var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "startPageId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "category",
                  label: "客户端",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(scope.row.category == 1 ? "Android" : "IOS")
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "editionNum",
                  label: "版本号",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "editionName",
                  label: "版本名称",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "downLoadUrl",
                  label: "下载地址",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isMustUpdate",
                  label: "是否强制更新",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isMustUpdate == 1
                          ? _c("div", {
                              staticClass: "el-icon-check is_success_show",
                            })
                          : _c("div", {
                              staticClass: "el-icon-close is_error_show",
                            }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateContent",
                  label: "更新内容",
                  align: "center",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.updateContent) + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "updateTime", label: "更新时间", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.updateTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _vm.permission.updateVersionInfo
                              ? _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改版本信息",
            visible: _vm.dialogEditFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEditFormVisible = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                model: _vm.editForm,
                rules: _vm.editRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户端" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.editForm.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "category", $$v)
                        },
                        expression: "editForm.category",
                      },
                    },
                    _vm._l(_vm.categoryList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本名称", prop: "editionName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "205px" },
                    attrs: { size: "medium", placeholder: "请填写版本名称" },
                    model: {
                      value: _vm.editForm.editionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "editionName", $$v)
                      },
                      expression: "editForm.editionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "版本号", prop: "editionNum" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "205px" },
                    attrs: { size: "medium", placeholder: "请填写版本号" },
                    model: {
                      value: _vm.editForm.editionNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "editionNum", $$v)
                      },
                      expression: "editForm.editionNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新内容", prop: "updateContent" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "medium",
                      type: "textarea",
                      rows: 4,
                      placeholder: "请填写更新内容",
                    },
                    model: {
                      value: _vm.editForm.updateContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "updateContent", $$v)
                      },
                      expression: "editForm.updateContent",
                    },
                  }),
                ],
                1
              ),
              _vm.editForm.category == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "下载地址", prop: "downLoadUrl" } },
                    [
                      _c("UploadFileNewOne", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          fileList: _vm.editForm.downLoadUrl,
                          limit: 1,
                          accept: ".apk",
                          disabled: true,
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(_vm.editForm, "downLoadUrl", $event)
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(_vm.editForm, "downLoadUrl", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editForm.category == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "下载地址", prop: "downLoadUrl" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "medium",
                          placeholder: "请填写下载地址",
                        },
                        model: {
                          value: _vm.editForm.downLoadUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "downLoadUrl", $$v)
                          },
                          expression: "editForm.downLoadUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "是否强制更新" } },
                [
                  _c("el-checkbox", {
                    attrs: { "false-label": 0, "true-label": 1 },
                    model: {
                      value: _vm.editForm.isMustUpdate,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "isMustUpdate", $$v)
                      },
                      expression: "editForm.isMustUpdate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", icon: "el-icon-switch-button" },
                  on: {
                    click: function ($event) {
                      _vm.dialogEditFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    icon: "el-icon-document-checked",
                    type: "primary",
                    loading: _vm.editbuttonloading,
                  },
                  on: { click: _vm.onEditSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }