<template>
  <div class="container">
    <div class="page-container">
      <el-table
        ref="table"
        :data="tableData"
        border
        stripe
        :row-key="'startPageId'"
        v-loading="showLoading"
        :height="tableHeight"
      >
         <el-table-column prop="category" label="客户端" width="100" align="center">
          <template slot-scope="scope">{{
            scope.row.category == 1 ? "Android" : "IOS"
          }}</template>
        </el-table-column>
        <el-table-column
          prop="editionNum"
          label="版本号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="editionName"
          label="版本名称"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column prop="downLoadUrl" label="下载地址" min-width="140"></el-table-column>
        <el-table-column prop="isMustUpdate" label="是否强制更新"  width="100"
          align="center">
          <template slot-scope="scope">
            <div
              v-if="scope.row.isMustUpdate == 1"
              class="el-icon-check is_success_show"
            ></div>
            <div v-else class="el-icon-close is_error_show"></div>
          </template>
        </el-table-column>

         <el-table-column prop="updateContent" label="更新内容" align="center" min-width="140">
          <template slot-scope="scope">
            {{ scope.row.updateContent }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="更新时间"
          width="160"
        >
         <template slot-scope="scope">
            {{ scope.row.updateTime | formatDate }}
          </template>
        
        </el-table-column>
        <el-table-column label="操作" width="100"
          align="center">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button class="success" icon="el-icon-edit-outline" type="text" v-if="permission.updateVersionInfo"
                @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

       <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
    </div>

    <el-dialog
      title="修改版本信息"
      :visible.sync="dialogEditFormVisible"
      :modal="false"
      :destroy-on-close="true"
      fullscreen
      @close="resetAddOrEditData"
    >
      <el-form
        ref="editForm"
        label-width="120px"
        :model="editForm"
        :rules="editRules"
      >
      <el-form-item label="客户端">
        <el-select v-model="editForm.category" disabled>
            <el-option v-for="item in categoryList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

       <el-form-item label="版本名称" prop="editionName">
          <el-input
            size="medium"
            style="width: 205px"
            v-model="editForm.editionName"
            placeholder="请填写版本名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="editionNum">
          <el-input
            size="medium"
            style="width: 205px"
            v-model="editForm.editionNum"
            placeholder="请填写版本号"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新内容" prop="updateContent">
          <el-input
            size="medium"
            type="textarea"
            :rows="4"
             style="width: 400px;"
            v-model="editForm.updateContent"
            placeholder="请填写更新内容"
          ></el-input>
        </el-form-item>
        <el-form-item label="下载地址" prop="downLoadUrl" v-if="editForm.category == 1">  
            <UploadFileNewOne :fileList.sync="editForm.downLoadUrl" :limit="1" :accept="'.apk'" :disabled="true"
                style="width:100%;">
            </UploadFileNewOne>
        </el-form-item>
        <el-form-item label="下载地址" prop="downLoadUrl" v-if="editForm.category == 2">  
            <el-input
            size="medium"
            v-model="editForm.downLoadUrl"
            placeholder="请填写下载地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="是否强制更新">
            <el-checkbox v-model="editForm.isMustUpdate" :false-label="0" :true-label="1"></el-checkbox>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="medium"
          icon="el-icon-switch-button"
          @click="dialogEditFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          icon="el-icon-document-checked"
          type="primary"
          :loading="editbuttonloading"
          @click="onEditSubmit"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getList } from "@/tools/getList.js";
import systemSetManager from "@/request/systemSetManager.js";
import UploadFileNewOne from "@/components/common/subgroup/UploadFileNewOne.vue"; //上传图片
export default {
  mixins: [getList],
  components: {
    UploadFileNewOne,
  },
  data() {
        var validateCallEditionNum = (rule, value, callback) => {
      if (this.editErrors.editionNum) {
        callback(new Error(this.editErrors.editionNum));
      } else {
        callback();
      }
    };
    var validateCallEditionName = (rule, value, callback) => {
      if (this.editErrors.editionName) {
        callback(new Error(this.editErrors.editionName));
      } else {
        callback();
      }
    };
    var validateCallDownLoadUrl = (rule, value, callback) => {
      if (this.editErrors.downLoadUrl) {
        callback(new Error(this.editErrors.downLoadUrl));
      } else {
        callback();
      }
    };
    var validateCallUpdateContent = (rule, value, callback) => {
      if (this.editErrors.updateContent) {
        callback(new Error(this.editErrors.updateContent));
      } else {
        callback();
      }
    };
    return {
      requst: {
        module: systemSetManager,
        url: "versionInfoList",
        addUrl: "",
        updateUrl: "",
      },
      query: {},
      categoryList:[
        {
            value:1,
            label:"Android"
        }, {
            value:2,
            label:"IOS"
        }
      ],

      dialogEditFormVisible: false,
      editForm: {
        versionId: 0,
        editionNum: "",
        editionName: "",
        downLoadUrl: "",
        updateContent: "",
        category: "",
        isMustUpdate: "",
        updateTime:""
      },
      markTypeList:[{
        value:0,
        label:"无动作"
      },{
        value:1,
        label:"外部链接"
      },{
        value:2,
        label:"内部链接"
      }],
      editbuttonloading: false,
      editRules: {
        editionNum: [
          { required: true, message: "请输入版本号", trigger: "blur" },
          { validator: validateCallEditionNum },
        ],
        editionName: [
          { required: true, message: "请输入版本名称", trigger: "blur" },
          { validator: validateCallEditionName },
        ],
        downLoadUrl: [
          { required: true, message: "请输入下载地址", trigger: "blur" },
          { validator: validateCallDownLoadUrl },
        ],
        updateContent: [
          { required: true, message: "请输入更新内容", trigger: "blur" },
          { validator: validateCallUpdateContent },
        ],
      },
      editErrors: {
        editionNum: "",
        editionName: "",
        downLoadUrl: "",
        updateContent: "",
      },
    };
  },
  created: async function () {},
  mounted: function () {},
  methods: {
    resetAddOrEditData() {
      this.dialogEditFormVisible = false;
      this.editForm.versionId = 0;
      this.editForm.editionNum = "";
      this.editForm.editionName = "";
      this.editForm.downLoadUrl = "";
      this.editForm.updateContent = "";
      this.editForm.category = "";
      this.editForm.isMustUpdate = "";
      this.editForm.updateTime = "";
      this.$refs["editForm"].clearValidate();
    },

    //ToEdit
    async clickEditInfo(row) {
       this.editForm.versionId = row.versionId;
      this.editForm.editionNum = row.editionNum;
      this.editForm.editionName = row.editionName;
      this.editForm.downLoadUrl = row.downLoadUrl;
      this.editForm.updateContent = row.updateContent;
      this.editForm.category = row.category;
      this.editForm.isMustUpdate = row.isMustUpdate;
      this.editForm.updateTime = row.updateTime;
      this.dialogEditFormVisible = true;
    },

    //提交添加/修改信息
    onEditSubmit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.editForm.updateTime = new Date().getTime();
            console.log(this.editForm.updateTime);
            this.editbuttonloading = true;
            let data = await systemSetManager.versionInfoUpdate(this.editForm);
            if (data.code == 200) {
              this.dialogEditFormVisible = false;
              this.refreshData();
              this.$message({ message: "修改成功", type: "success" });
            }
            this.editbuttonloading = false;
          } catch (error) {
            this.editbuttonloading = false;
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.pageblock {
  text-align: right;
  margin-top: 5px;
}
.uploadImg .uploadImages {
  float: left;
  width: 100px;
  height: 100px;
}
</style>
