var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "explainId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "explainId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "explainTitle",
                  label: "标题",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "explainContent",
                  label: "内容",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isEditor == 2
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-image",
                                  {
                                    staticClass: "table-td-thumb",
                                    staticStyle: { margin: "0px" },
                                    attrs: {
                                      src: scope.row.explainContent,
                                      "preview-src-list": [
                                        scope.row.explainContent,
                                      ],
                                      fit: "cover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "image-slot",
                                        attrs: { slot: "error" },
                                        slot: "error",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-picture-outline",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : scope.row.isEditor == 4
                          ? _c(
                              "span",
                              [
                                scope.row.explainContent == 1
                                  ? _c("el-button", {
                                      attrs: {
                                        size: "mini",
                                        type: "success",
                                        icon: "el-icon-check",
                                        circle: "",
                                      },
                                    })
                                  : _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        size: "mini",
                                        icon: "el-icon-close",
                                        circle: "",
                                      },
                                    }),
                              ],
                              1
                            )
                          : scope.row.isEditor == 1
                          ? _c("span", [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-link",
                                    { attrs: { type: "primary" } },
                                    [_vm._v("详情")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                " " + _vm._s(scope.row.explainContent) + " "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "130px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "编辑",
                              placement: "top",
                            },
                          },
                          [
                            _vm.permission.updateExplainSetting
                              ? _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-edit-outline",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.page,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.page_size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.helperId ? "编辑" : "添加"}说明设置`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "120px",
                rules: _vm.addRules,
                model: _vm.editForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标题", prop: "explainTitle" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "medium",
                      placeholder: "请输入标题",
                      disabled: true,
                    },
                    model: {
                      value: _vm.editForm.explainTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "explainTitle", $$v)
                      },
                      expression: "editForm.explainTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "详情", prop: "explainContent" },
                },
                [
                  _vm.editForm.isEditor == 2
                    ? _c("span", [
                        _c(
                          "div",
                          { staticClass: "uploadImg" },
                          [
                            _c("UploadImage", {
                              attrs: {
                                limit: 1,
                                imgList: _vm.editForm.explainContent,
                                accept: "image/*",
                              },
                              on: {
                                "update:imgList": function ($event) {
                                  return _vm.$set(
                                    _vm.editForm,
                                    "explainContent",
                                    $event
                                  )
                                },
                                "update:img-list": function ($event) {
                                  return _vm.$set(
                                    _vm.editForm,
                                    "explainContent",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.editForm.isEditor == 1
                    ? _c(
                        "span",
                        [
                          _vm.addOrEditDialog
                            ? _c("Editor", {
                                model: {
                                  value: _vm.editForm.explainContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.editForm,
                                      "explainContent",
                                      $$v
                                    )
                                  },
                                  expression: "editForm.explainContent",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.editForm.isEditor == 4
                    ? _c(
                        "span",
                        [
                          _vm.editForm.explainContent == 1
                            ? _c("el-button", {
                                attrs: {
                                  size: "mini",
                                  type: "success",
                                  icon: "el-icon-check",
                                  circle: "",
                                },
                              })
                            : _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  size: "mini",
                                  icon: "el-icon-close",
                                  circle: "",
                                },
                              }),
                        ],
                        1
                      )
                    : _vm.editForm.isEditor == 3
                    ? _c(
                        "span",
                        [
                          _c("el-input", {
                            staticStyle: { width: "600px" },
                            attrs: {
                              type: "textarea",
                              rows: 8,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.editForm.explainContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "explainContent", $$v)
                              },
                              expression: "editForm.explainContent",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.editForm.isEditor == 6
                    ? _c("span")
                    : _c(
                        "span",
                        [
                          _c("el-input", {
                            staticStyle: { width: "1000px" },
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.editForm.explainContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "explainContent", $$v)
                              },
                              expression: "editForm.explainContent",
                            },
                          }),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium" },
                  on: { click: _vm.resetAddOrEditData },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "medium",
                    type: "primary",
                    loading: _vm.addOrEditBtnLoading,
                  },
                  on: { click: _vm.onEditSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }