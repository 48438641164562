var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "请输入终端编号" },
                    model: {
                      value: _vm.query.terminalNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "terminalNo", $$v)
                      },
                      expression: "query.terminalNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.terminalSave
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "id",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "terminalNo",
                  "min-width": "120",
                  label: "终端编号",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "门店logo",
                  "min-width": "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            attrs: {
                              src: scope.row.storeLogo,
                              "preview-src-list": [scope.row.storeLogo],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeName",
                  label: "门店名称",
                  "min-width": "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "storeAddress",
                  label: "门店地址",
                  "min-width": "230",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDate")(scope.row.createTime)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "110px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.terminalUpdate
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.terminalRemove
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("show-content", {
        attrs: { title: _vm.rowInfo.title, content: _vm.rowInfo.content },
        model: {
          value: _vm.dialogSeeContent,
          callback: function ($$v) {
            _vm.dialogSeeContent = $$v
          },
          expression: "dialogSeeContent",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `${_vm.editForm.id ? "编辑" : "添加"}设备`,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                "label-width": "140px",
                model: _vm.editForm,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终端编号", prop: "terminalNo" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入终端编号" },
                    model: {
                      value: _vm.editForm.terminalNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "terminalNo", $$v)
                      },
                      expression: "editForm.terminalNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "is-required",
                  attrs: { label: "关联门店", prop: "storeName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      size: "medium",
                      placeholder: "请选择门店",
                      disabled: "",
                    },
                    model: {
                      value: _vm.editForm.storeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "storeName", $$v)
                      },
                      expression: "editForm.storeName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.createIdChangeNew },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择门店",
            visible: _vm.dialogStoreFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStoreFormVisible = $event
            },
          },
        },
        [
          _vm.dialogStoreFormVisible
            ? _c("StoreList", {
                key: "key" + _vm.currentKey,
                on: { changeSelectStore: _vm.handleChangeSelectStore },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }