<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <!-- <el-form-item>
                    <el-input type="text" placeholder="请输入" v-model="query.terminalNo"></el-input>
                </el-form-item> -->
                <el-form-item>
                    <el-cascader v-model="selectTypeArr" :options="consultationTypeList" :props="{
                        'value': 'id', 'label': 'typeName'
                    }" placeholder="请选择问诊类型" @change="handleChange" clearable></el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="onAdd" v-if="permission.quickPhrasesSave">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="typeName" min-width="120" label="问诊类型名称" align="center"></el-table-column>
                <el-table-column prop="quickPhrasesContent" label="快捷语内容" min-width="300"
                    align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110px" align="center">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.quickPhrasesUpdate">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.quickPhrasesRemove">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看快捷语详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加快捷语 -->
        <el-dialog :title="`${editForm.id ? '编辑' : '添加'}快捷语`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="问诊类型" prop="consultationTypeId">
                    <el-cascader v-model="editSelectTypeArr" :options="consultationTypeList" :props="{
                        'value': 'id', 'label': 'typeName'
                    }"  placeholder="请选择问诊类型" @change="handleEditChange" clearable=""></el-cascader>
                </el-form-item>
                <el-form-item label="快捷语内容" prop="quickPhrasesContent">
                    <el-input type="textarea" size="medium" v-model="editForm.quickPhrasesContent" placeholder="请输入快捷语内容"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import systemSetManager from '@/request/systemSetManager.js'
import { quickPhrasesModule } from "@/request/systemSetManager.js";
import ShowContent from '@/components/common/subgroup/ShowContent.vue'

export default {
    mixins: [getList],
    components: {
        ShowContent,
    },
    data() {
        return {
            requst: {
                module: systemSetManager,
                url: 'quickPhrasesList',
                addUrl: '',
                updateUrl: '',
            },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                consultationTypeId: '', //搜索词
            },
            selectTypeArr: [],
            consultationTypeList: [],
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                id: 0,
                consultationTypeId: '',
                quickPhrasesContent: '',
            },
            editSelectTypeArr: [],
            addRules: {
                quickPhrasesContent: [
                    { required: true, message: '请输入快捷语内容', trigger: 'blur' },
                ],
                consultationTypeId: [{ required: true, message: "请选择问诊类型", trigger: "blur" }],
            },

        }
    },
    created() {
        this.handlePermission()
        if (this.permission.selectConsultationTypeList) {
            this.getConsultationTypeList()
        }
    },
    methods: {
        async getConsultationTypeList() {
            try {
                let data = await systemSetManager.consultationTypeList({
                    isDelete: 0
                });
                if (data.code == 200) {
                    this.consultationTypeList = this.toTree(data.data)
                }
            } catch (error) {
                //
            }
        },
        //处理数据
        toTree(data) {
            let result = []
            if (!Array.isArray(data)) {
                return result
            }
            data.forEach((item) => {
                item.children = ''
            })
            let map = {}
            data.forEach((item) => {
                map[item.id] = item
            })
            data.forEach((item) => {
                let parent = map[item.parentId]
                if (parent) {
                    (parent.children || (parent.children = [])).push(item)
                } else {
                    result.push(item)
                }
            })
            return result
        },
        handleChange(valueArr) {
            if (valueArr.length) {
                this.query.consultationTypeId = valueArr[valueArr.length - 1];
            } else {
                this.query.consultationTypeId = '';
            }
        },
        handleEditChange(valueArr) {
            if (valueArr.length) {
                this.editForm.consultationTypeId = valueArr[valueArr.length - 1];
            } else {
                this.editForm.consultationTypeId = '';
            }
        },
        hoverContent(row) {
            this.rowInfo = row
            this.dialogSeeContent = true
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    this.addOrEditBtnLoading = true
                    let dataValue = JSON.parse(JSON.stringify(this.editForm))
                    if (this.editForm.id > 0) {
                        await quickPhrasesModule.quickPhrasesUpdate(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    } else {
                        await quickPhrasesModule.quickPhrasesSave(dataValue).then((response) => {
                            this.addOrEditBtnLoading = false;
                            switch (response.data.code) {
                                case 200:
                                    this.$message({ message: response.data.message, type: 'success', })
                                    this.$emit('success')
                                    this.refresCurrentPagehData()
                                    this.resetAddOrEditData()
                                    break;
                                default:
                                    this.$message({
                                        message: response.data.message,
                                        type: 'error'
                                    });
                                    // this.$message.error(response.data.message);
                                    break;
                            }
                        }).catch((error) => {
                            //
                            this.addOrEditBtnLoading = false;
                        });
                    }
                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                id: 0,
                consultationTypeId: '',
                quickPhrasesContent: '',
            }
            this.editSelectTypeArr = []
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.editForm = {
                id: row.id,
                consultationTypeId: row.consultationTypeId,
                quickPhrasesContent: row.quickPhrasesContent,
            }
            if (row.parentId*1 > 0) {
                this.editSelectTypeArr = [row.parentId*1, row.consultationTypeId]
            } else {
                this.editSelectTypeArr = [row.consultationTypeId]
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.quickPhrasesRemove({
                    id: row.id,
                })
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success',
                    })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },





    },
}
</script>

<style scoped></style>