<template>
  <div class="container">
    <div class="page-container">
      <el-form :inline="true" class="fr-s-s">
        <el-form-item>
          <el-select v-model="query.advertType" placeholder="请选择类型" clearable>
            <el-option v-for="item in searchForm.typeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="query.moduleId" placeholder="请选择展示模块" clearable>
            <el-option v-for="item in searchForm.moduleList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="refreshData">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain v-if="permission.saveAdvertInfo" @click="onAdd">添加</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="table" :data="tableData" border stripe :row-key="'advertId'" v-loading="showLoading" :height="tableHeight">
        <el-table-column prop="advertId" label="编号" align="center" width="60">
          <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="图片" width="100" align="center">
          <template slot-scope="scope">
            <el-image class="table-td-thumb" style="max-width:40px;max-height:40px" :src="scope.row.imgUrl" :preview-src-list="[scope.row.imgUrl]" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="advertTitle" label="广告标题" align="center" min-width="120"></el-table-column>
        <el-table-column prop="" label="广告类型" width="100px" align="center">
          <template slot-scope="scope">
            {{ scope.row.advertType | getStateName(searchForm.typeList) }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="展示模块" width="120px" align="center">
          <template slot-scope="scope">
            {{ scope.row.moduleId | getStateName(searchForm.moduleList) }}
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否显示" align="center">
          <template slot-scope="scope">
            <i :class="[scope.row.isShow == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
          </template>
        </el-table-column>
        <el-table-column prop="" label="详情" width="120px" align="center">
          <template slot-scope="scope">
            <a v-if="scope.row.advertType == 1 || scope.row.advertType == 2" @click="linUrlDetail(scope.row)">
              <el-link type="primary">查看详情</el-link>
            </a>
          </template>
        </el-table-column> 
        <el-table-column prop="orderWeight" label="权重" align="center" width="100"></el-table-column>
        <el-table-column label="操作" width="100px" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button class="success" icon="el-icon-edit-outline" type="text" v-if="permission.updateAdvertInfo" @click="clickEditInfo(scope.row)"></el-button>
            </el-tooltip>

            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-popconfirm title="您确定要删除么？" icon-color="red" v-if="permission.removeAdvertInfo" @confirm="deleteAdmin(scope.row, scope.$index)">
                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                </el-button>
              </el-popconfirm>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit" layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <!-- 添加 / 编辑 -->
    <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    <el-dialog :title="`${editForm.advertId ? '编辑' : '添加'}广告`" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
      <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
        <el-form-item label="广告标题" class="is-required" prop="advertTitle">
          <el-input size="medium" v-model="editForm.advertTitle" placeholder="请填写广告标题"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="imgUrl">
          <UploadImageNewOne :limit="1" :imgList.sync="editForm.imgUrl" accept="image/*"></UploadImageNewOne>
          <span style="color:red">最优比例117:40</span>
        </el-form-item>
        <el-form-item label="广告类型" prop="advertType">
          <el-select v-model="editForm.advertType" placeholder="请选择类型" @change="changeAdverType">
            <el-option v-for="item in searchForm.typeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="展示模块" prop="moduleId">
          <el-select v-model="editForm.moduleId" placeholder="请选择展示模块">
            <el-option v-for="item in searchForm.moduleList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="外部链接" class="is-required" prop="linkUrl" v-if="editForm.advertType == 2">
          <el-input size="medium" v-model="editForm.linkUrl" placeholder="请填写外部链接"></el-input>
        </el-form-item>

        <el-form-item label="内容" prop="advertContent" v-if="editForm.advertType == 1">
          <Editor v-if="addOrEditDialog" v-model="editForm.advertContent"></Editor>
        </el-form-item>
        <el-form-item label="圈子信息" prop="keyId" v-if="editForm.advertType == 3">
          <el-button style="margin-bottom:10px" @click="selectCircleHandle">选择圈子</el-button>
          <el-input size="medium" v-model="editForm.keyId" placeholder="圈子编号" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="isShow">
          <el-checkbox v-model="editForm.isShow" :false-label="0" :true-label="1"></el-checkbox>
        </el-form-item>
        <el-form-item label="权重" prop="orderWeight">
          <el-input type="number" v-model="editForm.orderWeight" placeholder="请填写权重"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetAddOrEditData">取 消</el-button>
        <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 用户圈子列表 -->
    <el-dialog title="圈子列表" :visible.sync="dialogSelectCircleVisible" :modal="false" :destroy-on-close="true" class="absolute" fullscreen>
      <CircleList @selectCircle="changeCircleHandle" v-if="dialogSelectCircleVisible" />
    </el-dialog>
  </div>
</template>

<script>
import { getList } from '@/tools/getList.js'
import systemSetManager from '@/request/systemSetManager.js'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'
import Editor from '@/components/common/subgroup/Editor.vue' //编辑器
import UploadImageNewOne from '@/components/common/subgroup/UploadImageNewOne.vue' //上传图片
import CircleList from '../circle/list/CircleList.vue'

export default {
  mixins: [getList],
  components: {
    ShowContent,
    Editor,
    UploadImageNewOne,
    CircleList
  },
  data() {
    var validatUrl = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入外部链接'))
      } else {
        var regex = /^(http|https):\/\/[\S]+$/
        if (regex.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的外部链接'))
        }
      }
    }
    return {
      requst: {
        module: systemSetManager,
        url: 'advertList',
        addUrl: 'advertInfoSave',
        updateUrl: 'advertInfoUpdate',
      },
      query: {
        advertType: '',
        moduleId: '',
      },
      dialogSeeContent: false,
      rowInfo: {},

      searchForm: {
        typeList: [
          {
            value: 0,
            label: '无动作',
          },
          {
            value: 1,
            label: '图文广告',
          },
          {
            value: 2,
            label: '外部链接',
          },
          {
            value: 3,
            label: '圈子',
          },
        ],
        moduleList: [
          {
            value: 0,
            label: '不限',
          },
          {
            value: 1,
            label: '首页启动弹出',
          },
          {
            value: 2,
            label: '首页轮播',
          },
          {
            value: 3,
            label: '积分商城首页',
          },
          //二期 4:动态详情；5:积分商品详情
          {
            value: 4,
            label: '动态',
          },
          {
            value: 5,
            label: '积分商品',
          },

        ],
      },
      //添加广告
      addOrEditDialog: false,
      addOrEditBtnLoading: false,
      editForm: {
        advertId: 0,
        advertTitle: '',
        keyId: 0,
        moduleId: 1,
        advertType: 0,
        isShow: 1,
        orderWeight: 9,
        advertContent: '',
        imgUrl: '',
        linkUrl: '',
      },

      addRules: {
        advertTitle: [
          { required: true, message: '请输入广告标题', trigger: 'change' },
        ],
        moduleId: [
          { required: true, message: '请选择展示模块', trigger: 'change' },
        ],
        advertType: [
          { required: true, message: '请选择广告类型', trigger: 'change' },
        ],
        orderWeight: [
          { required: true, message: '请输入权重', trigger: 'change' },
        ],
        advertContent: [
          { required: true, message: '请输入图文信息', trigger: 'change' },
        ],
        imgUrl: [{ required: true, message: '请选择图片', trigger: 'blur' }],
        keyId: [{ required: true, message: '请选择圈子', trigger: 'blur' }],
        linkUrl: [
          { required: true, message: '请输入外部链接', trigger: 'change' },
          { validator: validatUrl, trigger: 'blur' },
        ],
      },
      //查看圈子
      dialogSelectCircleVisible: false,
    }
  },
  methods: {
    onAdd() {
      this.resetAddOrEditData()
      this.addOrEditDialog = true
    },
    clickEditInfo(row) {
      this.editForm.advertId = row.advertId
      this.editForm.advertTitle = row.advertTitle
      this.editForm.keyId = row.keyId
      this.editForm.moduleId = row.moduleId * 1
      this.editForm.advertType = row.advertType * 1
      this.editForm.isShow = row.isShow
      this.editForm.orderWeight = row.orderWeight
      this.editForm.advertContent = row.advertContent
      this.editForm.imgUrl = row.imgUrl
      this.editForm.linkUrl = row.linkUrl
      this.addOrEditDialog = true
      console.log()
    },

    resetAddOrEditData() {
      this.addOrEditDialog = false
      this.editForm.advertId = 0
      this.editForm.advertTitle = ''
      this.editForm.keyId = 0
      this.editForm.moduleId = 0
      this.editForm.advertType = 0
      this.editForm.isShow = 1
      this.editForm.orderWeight = 9
      this.editForm.advertContent = ''
      this.editForm.imgUrl = ''
      this.editForm.linkUrl = ''
    },
    changeAdverType() {
      this.editForm.linkUrl = ''
      this.editForm.advertContent = ''
      this.editForm.keyId = 0
      this.$refs['editForm'].clearValidate()
    },
    async clickAddOrEditConfirm() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.addbuttonloading = true
            let data = await this.requst.module[
              this.editForm.advertId > 0
                ? this.requst.updateUrl
                : this.requst.addUrl
            ](this.editForm)
            if (data.code == 200) {
              this.$message.success(data.message)
              this.refreshData()
              this.resetAddOrEditData()
            }
            this.addbuttonloading = false
          } catch (error) {
            this.addOrEditBtnLoading = false
          }
        } else {
          return false
        }
      })
    },
    async deleteAdmin(row) {
      try {
        let data = await systemSetManager.advertInfoRemove({
          advertId: row.advertId,
        })
        if (data.code) {
          this.$message.success(data.message)
          this.refresCurrentPagehData()
          this.resetAddOrEditData()
        }
      } catch (error) {
        // console.log(error);
      }
    },
    //详情
    linUrlDetail(row) {
      switch (row.advertType) {
        case 1:
          this.rowInfo.title = '图文详情'
          this.rowInfo.content = row.advertContent
          break
        case 2:
          this.rowInfo.title = '外部链接'
          this.rowInfo.content = row.linkUrl
          break
        default:
          break
      }
      this.dialogSeeContent = true
    },
    //选择圈子
    selectCircleHandle(row) {
      if (this.permission.selectCircleList) {
        this.dialogSelectCircleVisible = true
      } else {
        this.$message.error('您没有选择圈子的权限')
      }
    },
    changeCircleHandle(row) {
      this.editForm.keyId = row.circleId
      this.dialogSelectCircleVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>