var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "warp fr-s-c" },
    [
      _vm._l(_vm.fileList, function (i, v) {
        return _c(
          "div",
          { key: v, staticClass: "fr-s-c relative m-r-10" },
          [
            _c(
              "el-image",
              {
                staticClass: "img-view",
                attrs: { src: i, "preview-src-list": _vm.fileList },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "w-1 h-1 fr-c",
                    staticStyle: { background: "#f9f9fa" },
                    attrs: { slot: "error" },
                    slot: "error",
                  },
                  [_c("i", { staticClass: "el-icon-picture-outline" })]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "fr-c del-content",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.hoverDel(v)
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/img/del_icon.png"),
                    alt: "",
                  },
                }),
              ]
            ),
          ],
          1
        )
      }),
      _vm.fileList.length < _vm.limit
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.fileLoading,
                  expression: "fileLoading",
                },
              ],
              staticClass: "choose-img fr-c img-view relative",
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../assets/img/uploadImg.png"),
                  alt: "",
                },
              }),
              _c("input", {
                ref: "choose",
                staticClass: "img-view",
                staticStyle: { position: "absolute", opacity: "0" },
                attrs: {
                  id: "choose",
                  accept: "image/*",
                  multiple: _vm.limit > 1,
                  type: "file",
                },
                on: { change: _vm.changeFile },
              }),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }