<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请填写任务描述" v-model="query.taskDesc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input type="text" placeholder="请填写商品名" v-model="query.goodsName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.taskSave" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'taskId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="taskId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="任务logo" width="90" align="center">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" :src="scope.row.taskLogo"
                            :preview-src-list="[scope.row.taskLogo]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="taskTitle" label="任务名称" align="center"></el-table-column>
                <el-table-column prop="taskDesc" label="任务描述" align="center">
                    <template slot-scope="scope">
                        <a @click="showContentHandle(scope.row, 1)">
                            <el-link type="primary">查看</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="completedNumber" label="完成任务所需数量" align="center"></el-table-column>
                <el-table-column prop="goodsName" label="奖品" align="center"></el-table-column>
                <el-table-column label="操作" width="110px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.taskUpdate">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.taskRemove">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看任务详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加任务 -->
        <el-dialog :title="`${editForm.taskId ? '编辑' : '添加'}任务`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="140px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="任务logo" prop="taskLogo" class="is-required">
                    <UploadImageNewOne :limit="1" :imgList.sync="editForm.taskLogo" accept="image/*">
                    </UploadImageNewOne>
                </el-form-item>
                <el-form-item label="任务名称" prop="taskTitle">
                    <el-input size="medium" v-model="editForm.taskTitle" placeholder="请填写任务名称"></el-input>
                </el-form-item>
                <el-form-item label="任务描述" prop="taskDesc" class="is-required">
                    <el-input size="medium" type="textarea" maxlength="255"
                        v-model="editForm.taskDesc" prefix-icon="el-icon-user" placeholder="请填写任务描述"></el-input>
                </el-form-item>
                <el-form-item label="完成任务所需数量" prop="completedNumber">
                    <el-input size="medium" type="number" v-model="editForm.completedNumber" placeholder="请填写完成任务所需数量"></el-input>
                </el-form-item>
                <el-form-item label="奖品" prop="goodsName" class="is-required">
                    <el-input v-model="editForm.goodsName" placeholder="请选择奖品" disabled style="width: 400px"></el-input>
                    <el-button style="margin-left: 20px" type="primary" plain @click="createIdChangeNew">选择</el-button>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 选择奖品 -->
        <el-dialog title="选择奖品" :visible.sync="dialogGoodsFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <GoodsList :key="'key' + currentKey" :goodsType="2" @changeSelectGoods="handleChangeSelectGoods"
                v-if="dialogGoodsFormVisible">
            </GoodsList>
        </el-dialog>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import systemSetManager from "@/request/systemSetManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import UploadImageNewOne from "@/components/common/subgroup/UploadImageNewOne.vue"; //上传图片
import GoodsList from "@/components/page/goods/GoodsList.vue";

export default {
    mixins: [getList],
    components: {
        ShowContent,
        UploadImageNewOne,
        GoodsList,
    },
    data() {
        return {
            requst: {
                module: systemSetManager,
                url: 'taskList',
                addUrl: 'taskSave',
                updateUrl: 'taskUpdate',
            },
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            query: {
                goodsName: '',    //搜索词
                taskDesc: '',
            },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                taskId: 0,
                taskLogo: "",
                taskTitle: "",
                taskDesc: "",
                completedNumber: "",
                goodsId: "",
                goodsName: '',
            },
            addRules: {
                taskLogo: [{ required: true, message: "请上传任务logo", trigger: "blur" }],
                taskTitle: [
                    { required: true, message: "请填写任务名称", trigger: "blur" },
                ],
                taskDesc: [
                    { required: true, message: "请填写任务描述", trigger: "blur" },
                ],
                completedNumber: [
                    { required: true, message: "请填写完成任务所需数量", trigger: "change" },
                    {
                        pattern: /^[+]{0,1}(\d+)$/,
                        message: "完成任务所需数量必须为大于等于的0整数",
                        trigger: "change",
                    },
                ],
                goodsName: [
                    { required: true, message: "请选择奖品", trigger: "blur" },
                ],
            },
            currentKey: 1,
            dialogGoodsFormVisible: false,
        };
    },
    created() {

    },
    methods: {
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    this.rowInfo.title = "任务描述";
                    this.rowInfo.content = row.taskDesc;
                    this.dialogSeeContent = true;
                    break;

                default:
                    break;
            }
        },
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await this.requst.module[this.editForm.taskId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                taskId: 0,
                taskLogo: "",
                taskTitle: "",
                taskDesc: "",
                completedNumber: "",
                goodsId: "",
                goodsName: '',
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            console.log("row", row);
            this.editForm = {
                taskId: row.taskId,
                taskLogo: row.taskLogo,
                taskTitle: row.taskTitle,
                taskDesc: row.taskDesc,
                completedNumber: row.completedNumber,
                goodsId: row.goodsId,
                goodsName: row.goodsName,
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.taskRemove({ taskId: row.taskId });
                if (code === 200) {
                    // this.$message.success(message);
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    // this.$message({ message: "删除成功", type: "success" });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        createIdChangeNew() {
            this.dialogGoodsFormVisible = true;
        },
        handleChangeSelectGoods(goods) {
            console.log(goods);
            this.editForm.goodsId = goods.goodsId;
            this.editForm.goodsName = goods.goodsName;
            this.dialogGoodsFormVisible = false;
        },
    },
};
</script>

<style scoped>

</style>

