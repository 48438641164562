<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveConsultationTypeInfo"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'id'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="id" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="typeName" label="问卷类型"></el-table-column>
                <el-table-column label="下级类型" align="center" width="90" v-if="!parentId">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showContentHandle(scope.row, 1)">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110px" align="center">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateConsultationTypeInfo">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeConsultationTypeInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>

        <!-- 添加车主服务类别-->
        <el-dialog :title="`${addOrEditModel.id ? '编辑' : '添加'}问卷类型`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="150px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="问卷类型" prop="typeName">
                    <el-input size="medium" v-model="addOrEditModel.typeName" placeholder="请输入问卷类型"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>

        <!-- 问题选项 -->
        <el-dialog title="二级类型" :visible.sync="dialogShowContentVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <consultation-type-list v-if="dialogShowContentVisible" :parentId="currentId" />
        </el-dialog>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import systemSetManager from "@/request/systemSetManager.js";

export default {
    name: 'consultation-type-list',
    props: ['parentId'],
    mixins: [getList],
    components: {

    },
    data() {
        return {
            requst: {
                module: systemSetManager,
                url: 'consultationTypeList',
                addUrl: 'saveConsultationTypeInfo',
                updateUrl: 'updateConsultationTypeInfo',
            },
            query: {
                parentId: this.parentId ? this.parentId : 0,
                isDelete: 0,
            },

            // 添加/修改
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            addOrEditModel: {
                id: 0,
                typeName: "",
                parentId: this.parentId ? this.parentId : 0,
            },
            addRules: {
                typeName: [
                    { required: true, message: "请输入问卷类型", trigger: "blur" },
                ],
            },

            currentId: 0,
            dialogShowContentVisible: false,
        };
    },
    created() {

    },
    methods: {


        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj = {
                            typeName: this.addOrEditModel.typeName,
                            parentId: this.addOrEditModel.parentId,
                        }
                        if (this.addOrEditModel.id) {
                            obj.id = this.addOrEditModel.id
                        }
                        let dataValue = JSON.parse(JSON.stringify(obj))
                        let data = await this.requst.module[this.addOrEditModel.id > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.addOrEditModel = {
                id: 0,
                typeName: "",
                parentId: this.parentId ? this.parentId : 0,
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            this.addOrEditModel = {
                id: row.id,
                typeName: row.typeName,
                orderWeight: row.orderWeight,
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.removeConsultationTypeInfo({ id: row.id });
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        showContentHandle(row, type) {
            switch (type) {
                case 1:
                    {
                        this.currentId = row.id
                        this.dialogShowContentVisible = true
                    }
                    break;

                default:
                    break;
            }
        },
    },
};
</script>

<style scoped></style>
